var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block mb-1"},[_vm._v(_vm._s(_vm.$t('player.currentPlaylist')))]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.flux),function(oneFlux,index){return _c('div',{key:index,staticClass:"image-flux mr-2"},[(oneFlux.idImage > 0)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-3",class:{
          selected:
            oneFlux.idStream === _vm.currentFlux ||
            oneFlux.idSkeleton === _vm.currentFlux,
          disabled: _vm.disabled,
        },attrs:{"triggers":"focus","title":oneFlux.name,"src":(_vm.env === 'production' ? '/api' : 'http://localhost:5000/api') +
            "/brand/stream/" + (oneFlux.idImage) + "/cover","alt":"flux img","width":"27px","height":"27px"},on:{"click":function($event){return _vm.changeFlux(oneFlux)}}}):_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-3",class:{
          selected: oneFlux.idStream === _vm.currentFlux,
          disabled: _vm.disabled,
        },attrs:{"title":oneFlux.name,"src":oneFlux.name === 'MA RADIO'
            ? require('@/assets/img/cover-ma-radio.png')
            : require('@/assets/img/cover-playlist.png'),"alt":"flux img"},on:{"click":function($event){return _vm.changeFlux(oneFlux)}}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }