<template>
  <div class="audio-item">
    <b-row>
      <b-col
        cols="4"
        sm="3"
        class="cover-item"
        :class="{ 'is-playing': isPlaying }"
      >
        <img
          v-if="!fetchingPlayer"
          :src="coverImg"
          alt="cover image"
          class="w-100"
          @error="imageLoadError"
        />
        <!-- <div v-if="!fetchingPlayer" class="ico d-flex">
            <b-icon
              :icon="isPlaying ? 'pause-circle-fill' : 'play-circle-fill'"
              scale="4"
            ></b-icon>
          </div> -->
        <b-skeleton-img v-else width="100%" height="110px"></b-skeleton-img>
      </b-col>
      <b-col cols="8" sm="9" class="pl-0 pl-sm-3 d-flex align-items-center">
        <div class="audio-infos">
          <template v-if="!fetchingPlayer">
            <div class="d-flex">
              <span class="mr-2">{{ getSongName() }}</span>
              <!-- <div class="d-flex ml-auto">
                  <span class="like-dislike cursor-pointer mr-1">
                    <img src="@/assets/svg/heart.svg" width="22px" />
                  </span>
                  <span class="like-dislike cursor-pointer">
                    <img src="@/assets/svg/heart-broken.svg" width="22px" />
                  </span>
                </div> -->
            </div>

            <p class="artists">
              {{ getSongArtist() }}
            </p>
          </template>
          <div v-else>
            <b-skeleton width="155px" height="17px"></b-skeleton>
            <b-skeleton width="90px" height="15px" class="mt-3"></b-skeleton>
          </div>
          <PlayerControlAudioSetVolume :player="player" v-if="player" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PlayerControlAudioSetVolume from '@/components/PlayerControlAudioSetVolume'
export default {
  data() {
    return {
      audioFlux: null,
    }
  },
  components: {
    PlayerControlAudioSetVolume,
  },
  props: {
    song: {
      type: Object,
      required: true,
    },
    player: {
      type: Object,
      componentLoaded: false,
      required: true,
    },
  },
  methods: {
    imageLoadError() {
      const img = require(`@/assets/svg/default-${this.itemType(
        this.song.type
      )}.svg`)
      event.target.src = img
    },
    itemType(type) {
      if (!type) return 'cover'
      switch (type) {
        case 0:
          return ''
        case 1:
          return 'cover'
        case 2:
          return 'message'
        case 3:
          return 'video'
        case 4:
          return 'trigger'
        default:
          return 'cover'
      }
    },
    getSongArtist() {
      if (this.song.title) {
        const parts = this.song.title.split(/-(.+)/)

        if (parts.length >= 2) {
          parts.pop()
          return parts[0]
        } else return null
      } else return null
    },
    getSongName() {
      if (this.song.title) {
        const parts = this.song.title.split(/-(.+)/)
        if (parts.length < 2) {
          return parts[0]
        } else {
          parts.pop()
          return parts[1]
        }
      } else return null
    },
  },
  computed: {
    isPlaying() {
      return this.audioFlux !== null
    },
    coverImg() {
      if (this.song) {
        const src = `https://fileapi.radioshop.net/getcover.aspx?idfile=${this.song.idTrack}&ft=1201`
        return src
      } else return null
    },
    ...mapState({
      fetchingPlayer: (state) => state.player.fetchingPlayer,
    }),
  },
}
</script>

<style lang="scss" scoped>
.cover-item {
  white-space: nowrap;
  position: relative;
  transition: 0.3s;
  img {
    border-radius: 5px;
  }
  // .ico {
  //   cursor: pointer;
  //   opacity: 0;
  //   z-index: 30;
  //   position: absolute;
  //   top: 0;
  //   left: -5px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;
  //   height: 100%;
  // }
  // &:hover,
  // &:active,
  // &.is-playing {
  //   color: $primary;
  //   font-weight: 600;
  //   img {
  //     filter: brightness(0.5);
  //   }
  //   .ico {
  //     opacity: 1;
  //   }
  // }
}
.audio-infos {
  flex: 1;
  min-width: 0; /* or some value */
  p {
    font-size: 1rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.artists {
  font-weight: 300;
  font-size: 0.9rem;
  color: rgba($h-main-text, 0.8);
}

.like-dislike {
  transition: 0.2s;
  &:hover {
    transform: translateY(-2px);
  }
}
</style>
