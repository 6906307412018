<template>
  <div class="d-flex flex-column">
    <span class="d-block mb-1">{{ $t('player.currentPlaylist') }}</span>
    <div class="d-flex">
      <div
        v-for="(oneFlux, index) in flux"
        :key="index"
        class="image-flux mr-2"
      >
        <img
          @click="changeFlux(oneFlux)"
          v-b-tooltip
          triggers="focus"
          :title="oneFlux.name"
          v-if="oneFlux.idImage > 0"
          :src="
            (env === 'production' ? '/api' : 'http://localhost:5000/api') +
              `/brand/stream/${oneFlux.idImage}/cover`
          "
          alt="flux img"
          width="27px"
          height="27px"
          class="mr-3"
          :class="{
            selected:
              oneFlux.idStream === currentFlux ||
              oneFlux.idSkeleton === currentFlux,
            disabled: disabled,
          }"
        />
        <img
          v-b-tooltip
          :title="oneFlux.name"
          @click="changeFlux(oneFlux)"
          v-else
          :src="
            oneFlux.name === 'MA RADIO'
              ? require('@/assets/img/cover-ma-radio.png')
              : require('@/assets/img/cover-playlist.png')
          "
          alt="flux img"
          class="mr-3"
          :class="{
            selected: oneFlux.idStream === currentFlux,
            disabled: disabled,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flux: {
      type: Array,
      required: true,
    },
    currentFlux: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    changeFlux(flux) {
      const id = flux.idStream ? flux.idStream : flux.idSkeleton
      this.$emit('changeFlux', id)
      this.$root.$emit('bv::hide::tooltip')
    },
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
  },
}
</script>

<style lang="scss" scoped>
.image-flux {
  width: 70px;
  img {
    width: 100%;
    height: 70px;
    object-fit: contain;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.5;
    border-radius: 3px;
    &:hover {
      opacity: 1;
    }
    &.selected {
      opacity: 1;
      border: 3px solid $primary;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
</style>
