import { render, staticRenderFns } from "./PlayersControlTriggersItem.vue?vue&type=template&id=6122bfaa&scoped=true&"
import script from "./PlayersControlTriggersItem.vue?vue&type=script&lang=js&"
export * from "./PlayersControlTriggersItem.vue?vue&type=script&lang=js&"
import style0 from "./PlayersControlTriggersItem.vue?vue&type=style&index=0&id=6122bfaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6122bfaa",
  null
  
)

export default component.exports