<template>
  <div>
    <div class="audio-item">
      <b-row>
        <b-col cols="4" sm="3" class="cover-item">
          <b-skeleton-img width="100%" aspect="1:1"></b-skeleton-img>
        </b-col>
        <b-col
          cols="8"
          sm="9"
          class="pl-0 pl-sm-3 d-flex justify-content-center flex-column"
        >
          <div class="audio-infos">
            <b-skeleton width="155px" height="15px"></b-skeleton>
            <b-skeleton width="90px" height="12px" class="mt-3"></b-skeleton>
          </div>
          <div class="w-100 w-md-75 mt-2 d-flex align-items-center">
            <div class="ml-1 mr-3">
              <b-skeleton-img width="30px" height="30px"></b-skeleton-img>
            </div>

            <b-icon icon="volume-off-fill" scale="1.5"></b-icon>
            <div class="pl-2 pr-3 w-100">
              <b-skeleton width="100%" height="4px" class="mt-1"></b-skeleton>
            </div>
            <b-icon icon="volume-up-fill" scale="1.5" class="ml-1"></b-icon>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cover-item {
  white-space: nowrap;
  img {
    border-radius: 5px;
  }
}
.audio-infos {
  flex: 1;
  min-width: 0; /* or some value */
  p {
    font-size: 1rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
