<template>
  <b-container :class="{ 'pt-3': !isSingleSite }">
    <template v-if="!serverError">
      <b-row v-if="appaired">
        <b-col lg="6">
          <PlayersControlAudio
            v-if="player"
            :player="player"
            :refreshStatus="refreshStatus"
            @unpair="unpairConfirm"
          />
        </b-col>
        <b-col lg="6">
          <PlayersControlTriggers v-if="player" :player="player" />
        </b-col>
        <b-col class="mt-4"> </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <PlayersControlPairPlayer v-if="player" :player="player" />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <HServerError />
        </b-col>
      </b-row>
    </template>
    <ModalUnpairConfirm
      v-if="player && showUnpairConfirm"
      :showUnpairConfirm="showUnpairConfirm"
      :player="player"
      @unpair="unpairPlayer"
      @close="showUnpairConfirm = false"
    />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/components/base/event-bus.js'
import dateFormat from '@/components/dateFormat.js'

import playerService from '@/services/playerService'
import PlayersControlAudio from '@/components/PlayersControlAudio'
import PlayersControlTriggers from '@/components/PlayersControlTriggers'
import PlayersControlPairPlayer from '@/components/PlayersControlPairPlayer'
import ModalUnpairConfirm from '@/components/modals/ModalUnpairConfirm'
export default {
  components: {
    PlayersControlAudio,
    PlayersControlTriggers,
    PlayersControlPairPlayer,
    ModalUnpairConfirm,
  },
  data() {
    return {
      serverError: false,
      player: {},
      pullingData: null,
      appaired: true,
      refreshStatus: 1,
      showUnpairConfirm: false,
      switchingSite: false,
    }
  },
  computed: {
    detailBreadCrumbs() {
      if (this.player && this.player.name) return this.player.name
      else return ''
    },
    idPairing() {
      return this.player?.extended?.idPairingApplication
    },
    storeSelectedSiteId() {
      return this.$store.state.user?.selectedSite?.id
    },
    ...mapGetters({ isSingleSite: 'user/isSingleSite' }),
  },
  props: ['id'],
  methods: {
    unpairConfirm() {
      this.showUnpairConfirm = true
    },
    unpairPlayer() {
      this.appaired = false
      this.player.extended.idPairingApplication = 0
    },
    getPlaylist() {
      const todaysDate = this.$moment()
      const params = {
        IdBrand: null,
        IdSite: this.player.idPlayer,
        ItemType: 4,
        IdStream: this.player.extended.currentStream,
        IdSkeleton: this.player.extended.currentSkeleton,
        Date: dateFormat.encodeServerDate(todaysDate),
      }
      this.$store.dispatch('playlist/_clearPlaylist')
      this.$store.dispatch('playlist/_getPlaylist', params)
    },
    async fetchPlayer() {
      try {
        this.$store.dispatch('player/_fetchingPlayer', true)
        const res = await playerService.getPlayer(this.id)

        this.player = res.data
        this.appaired =
          res.data.extended.idPairingApplication === 0 ? false : true
        this.getPlaylist()
        clearTimeout(this.pullingData)
        this.pullingData = setTimeout(() => {
          this.refreshPlayer()
        }, 40000)
      } catch (e) {
        clearTimeout(this.pullingData)
        this.pullingData = null
        this.serverError = true
      } finally {
        this.$store.dispatch('player/_fetchingPlayer', false)
      }
    },
    async refreshPlayer() {
      const res = await playerService.refreshPlayerData(this.id)
      this.player.extended.currentPlaybackType = res.data.currentPlaybackType
      this.player.extended.currentStream = res.data.idCurrentStream
      this.player.extended.currentSkeleton = res.data.idCurrentSkeleton
      this.player.connected = res.data.connected
      this.player.lastIdApplication = res.data.lastIdApplication
      this.player.heartbeat = res.data.heartbeat
      this.player.extended.currentTrack = res.data.currentTrack
      this.player.extended.idPairingApplication = res.data.idPairingApplication
      this.refreshStatus++
      clearTimeout(this.pullingData)
      this.pullingData = setTimeout(() => {
        this.refreshPlayer()
      }, 40000)
    },
    playerOffer(offer) {
      if (offer) {
        switch (offer) {
          case 1:
            return 'radio'
          case 3:
            return 'vidéo'
          default:
            return ''
        }
      } else return ''
    },
    playerTypeText() {
      switch (this.player.extended.idPairingApplication) {
        case 1:
          return this.$t('player.playerType.linux')
        case 2:
          return this.$t('player.playerType.linux')
        case 3:
          return this.$t('player.playerType.androidApp')
        case 4:
          return this.$t('player.playerType.iosdApp')
        case 5:
          return this.$t('player.playerType.sonos')
        case 6:
          return this.$t('player.playerType.linux')
        case 7:
          return this.$t('player.playerType.extranet')
        case 8:
          return 'myHubcast'
        case 9:
          return this.$t('player.playerType.linux')
        case 11:
          return this.$t('player.playerType.webplayer')
        case 12:
          return { label: '', link: null }
        case 15:
          return this.$t('player.playerType.windowsPlayer')
        default:
          return { label: '', link: null }
      }
    },
  },
  watch: {
    idPairing(value, oldValue) {
      if (value === 0) this.appaired = false
      else {
        this.appaired = true
        if (oldValue === 0 && !this.switchingSite) {
          this.refreshPlayer()
          this.getPlaylist()
          this.$toast.success(
            `${this.$t('player.playerAppairToast1')} ${
              this.player.name
            } ${this.$t(
              'player.playerAppairToast2'
            )} ${this.playerTypeText()} ${this.$t('player.playerAppairToast3')}`
          )
          this.refreshStatus++
        }
      }
    },
    async storeSelectedSiteId() {
      if (this.id != this.storeSelectedSiteId && this.storeSelectedSiteId) {
        await this.$router.push(`/players/${this.storeSelectedSiteId}`)
        this.fetchPlayer()
        this.switchingSite = true
        setTimeout(() => {
          this.switchingSite = false
        }, 2000)
      }
    },
  },
  async created() {
    clearTimeout(this.pullingData)
    await this.fetchPlayer()
    this.$store.commit('user/SET_SELECTED_SITE', {
      id: parseInt(this.id),
      name: this.player.name,
    })

    EventBus.$on('fetchPlayer', () => {
      this.refreshPlayer()
    })
  },
  beforeDestroy() {
    clearTimeout(this.pullingData)
    EventBus.$off('fetchPlayer')
  },
}
</script>

<style lang="scss" scoped></style>
