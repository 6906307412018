<template>
  <div class="w-100 mt-3 d-flex align-items-center" v-if="player.offer != 3">
    <b-button
      :disabled="disableSkip"
      @click="skipCurrentTrack"
      v-if="
        player &&
          player.extended &&
          player.extended.canSkipCurrentTrack &&
          player.connected
      "
      class="skip-btn mr-4 bg-transparent border-0 pl-0"
    >
      <b-icon icon="skip-end-fill" scale="2"></b-icon>
    </b-button>
    <template v-if="player.playbackMode !== 2">
      <b-icon icon="volume-off-fill" scale="1.5"></b-icon>
      <div class="pl-2 pr-3 w-100">
        <b-form-input
          v-if="this.player && playerVolume != null"
          v-model="playerVolume"
          type="range"
          min="0"
          :disabled="disableVolume || !player.connected"
          max="255"
          step="2.55"
          class="range"
          :class="{ disabled: disableVolume || !player.connected }"
          @change="setNewVolume()"
        >
        </b-form-input>
      </div>
      <b-icon icon="volume-up-fill" scale="1.5" class="ml-1"></b-icon>
    </template>
  </div>
</template>

<script>
import playerService from '@/services/playerService'
import { EventBus } from '@/components/base/event-bus.js'
export default {
  data() {
    return {
      disableVolume: false,
      disableSkip: false,
      playerVolume: null,
    }
  },
  props: {
    player: {
      type: Object,
      componentLoaded: false,
      required: true,
    },
  },
  created() {
    this.playerVolume = this.player.extended.volume
  },
  methods: {
    async skipCurrentTrack() {
      this.disableSkip = true
      try {
        const params = {
          IdSite: this.player.idPlayer,
          IdBrand: this.$store.state.user.user.visibleBrands[0].id,
          IdFile: this.player.extended.idTriggerFile,
          FileName: this.player.extended.triggerFileName,
        }
        await playerService.skipCurrentTrack(params)
        this.$toast.success(
          'Le morceau suivant va être joué dans quelques secondes'
        )
        setTimeout(() => {
          EventBus.$emit('fetchPlayer')
        }, 7000)
        setTimeout(() => {
          this.disableSkip = false
        }, 7500)
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite, veuillez réessayer ultérieurement"
        )
        this.disableSkip = false
      }
    },
    setNewVolume() {
      this.disableVolume = true
      setTimeout(() => {
        this.disableVolume = false
      }, 5000)

      this.$toast.success(
        `Le changement de volume du player ${this.player.name} prendra effet dans quelques secondes.`
      )
      let params = {
        IdSite: this.player.idPlayer,
        Volume: parseInt(this.playerVolume),
      }
      playerService.setPlayerVolume(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.skip-btn {
  color: $h-main-text;
  transition: 0.2s;
  &:hover {
    color: $primary;
  }
  &.disabled {
    pointer-events: none;
    &:hover,
    &:active,
    &:focus {
      color: $h-main-text;
    }
  }

  &:focus,
  &:active {
    outline: none;
    border: 0;
    box-shadow: none;
    color: $primary !important;
  }
}

$height: 30px;
$thumb-height: 15px;
$track-height: 3px;

// colors
$upper-color: #cfcfcf;
$lower-color: $primary;
$thumb-color: $primary;
$thumb-color-disabled: #868686;
$thumb-hover-color: #e92d0e;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100%
  50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100%
  50% / 100% $track-height no-repeat transparent;
$lower-background-disabled: linear-gradient(
    to bottom,
    $thumb-color-disabled,
    $thumb-color-disabled
  )
  100% 50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }
  @return $val;
}

.range {
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: $height;
  overflow: hidden;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

// Webkit
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
  transition: 0.2s;
}
.range.disabled::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background-disabled;
}

.range::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  top: 50%;
  margin-top: (-$thumb-height/2);
  box-shadow: webkit-slider-thumb-shadow();
  transition: background-color 150ms;
}
.range.disabled::-webkit-slider-thumb {
  transition: 0.2s;
  background: $thumb-color-disabled;
  transition: background-color 150ms;
}

// Firefox
.range::-moz-range-track,
.range::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

.range::-moz-range-progress {
  background: $lower-background;
  transition: 0.2s;
}
.range.disabled::-moz-range-progress {
  background: $lower-background-disabled;
}

.range::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}
.range.disabled::-moz-range-thumb {
  background: $thumb-color-disabled;
}

// Internet Exploder
.range::-ms-track {
  width: 100%;
  height: $height;
  border: 0;
  // color needed to hide track marks
  color: transparent;
  background: transparent;
}

.range::-ms-fill-lower {
  background: $lower-background;
}

.range::-ms-fill-upper {
  background: $upper-background;
}

.range::-ms-thumb {
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  // IE Edge thinks it can support -webkit prefixes
  top: 0;
  margin: 0;
  box-shadow: none;
}

.range:hover,
.range:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-color;
  }
  &::-moz-range-thumb {
    background-color: $thumb-color;
  }
  &::-ms-thumb {
    background-color: $thumb-color;
  }
}
.range.disabled:hover,
.range.disabled:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-color-disabled;
  }
  &::-moz-range-thumb {
    background-color: $thumb-color-disabled;
  }
  &::-ms-thumb {
    background-color: $thumb-color-disabled;
  }
}
.range.disabled:hover,
.range.disabled:focus {
  cursor: not-allowed;
}
</style>
