<template>
  <h-card class="h-100 mt-3 mt-lg-0 " p0>
    <div class="pt-3">
      <audio class="d-none" ref="audioPreview" controls>
        <source :src="audioSrc" type="audio/mp3" />
      </audio>
      <button class="d-none" ref="playBtn" @click="playPreview">click</button>
      <h4 class="text-center mb-4 pt-1">{{ $t('player.triggersTitle') }}</h4>
    </div>
    <b-row>
      <b-col>
        <template v-if="player && playlistLoaded && player.idPlayer">
          <b-row>
            <b-col>
              <h-input
                v-model="search"
                size="lg"
                class="input-triggers"
                :placeholder="$t('player.triggerSearch')"
                left-icon="search"
              ></h-input>
            </b-col>
          </b-row>
          <div class="triggers-list" v-if="filteredTriggers.length">
            <template v-for="(trigger, index) in filteredTriggers">
              <PlayersControlTriggersItem
                @click.native="getItemPreview(trigger.idFile, trigger.type)"
                :trigger="trigger"
                :key="index"
                :player="player"
                :active="isPlayerItem(trigger.idFile)"
              />
            </template>
          </div>
          <div v-else class="text-center mb-5 mt-4">
            {{ $t('player.noTriggerAvailable') }}
          </div>
        </template>
        <template v-else>
          <div class="input-triggers">
            <b-skeleton type="input"></b-skeleton>
            <template>
              <PlayersControlTriggersItemSkeleton v-for="i in 7" :key="i" />
            </template>
          </div>
        </template>
      </b-col>
    </b-row>
  </h-card>
</template>

<script>
import { mapState } from 'vuex'
import PlayersControlTriggersItem from '@/components/PlayersControlTriggersItem'
import PlayersControlTriggersItemSkeleton from '@/components/skeleton/PlayersControlTriggersItemSkeleton'
import playerService from '@/services/playerService'
export default {
  components: {
    PlayersControlTriggersItem,
    PlayersControlTriggersItemSkeleton,
  },
  data() {
    return {
      search: '',
      audioSrc: null,
      currentPlayingId: null,
    }
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async getItemPreview(idFile, type) {
      if (idFile !== this.currentPlayingId) {
        this.currentPlayingId = idFile
        const res = await playerService.getPlaylistItemAudio(idFile, type)
        if (res && res.data) {
          this.audioSrc = res.data
          this.$refs.audioPreview.load()
          this.$refs.playBtn.click()
        }
      } else {
        this.$refs.audioPreview.pause()
        this.currentPlayingId = null
        this.audioSrc = null
      }
    },
    playPreview() {
      this.$refs.audioPreview.play()
    },
    isPlayerItem(idFile) {
      return idFile === this.currentPlayingId
    },
  },
  mounted() {
    if (this.$refs.audioPreview) {
      let audioElem = this.$refs.audioPreview
      audioElem.addEventListener('ended', () => {
        this.audioSrc = null
        this.currentPlayingId = null
      })
    }
  },
  computed: {
    filteredTriggers() {
      return this.triggersValid.filter((trigger) => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every((v) => trigger.name.toLowerCase().includes(v))
      })
    },
    triggersValid() {
      return this.triggers.filter(
        (trigger) => !trigger.name.includes('SILENCE100MS')
      )
    },
    ...mapState({
      triggers: (state) => state.playlist.playlist,
      playlistLoaded: (state) => state.playlist.playlistLoaded,
    }),
  },
}
</script>

<style lang="scss" scoped>
.input-triggers {
  padding: 0 15px;
  margin-bottom: 5px;
}
.triggers-list {
  overflow-y: auto;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 100px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media screen and (min-width: 1200px) {
  .triggers-list {
    max-height: 441px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.5px) {
  .triggers-list {
    max-height: 654px;
  }
}

@media screen and (max-width: 991.5px) {
  .triggers-list {
    max-height: 350px;
  }
}
</style>
