<template>
  <div>
    <div class="audio-item">
      <b-row>
        <b-col cols="3" class="cover-item">
          <img
            src="@/assets/svg/default-cover.svg"
            alt="cover image"
            class="w-100"
          />
        </b-col>
        <b-col cols="9" class="d-flex align-items-center">
          <div class="audio-infos">
            <div class="d-flex">
              <span>{{ $t('player.unknownTitle') }}</span>
            </div>
            <p class="artists">{{ $t('player.unknownArtist') }}</p>
            <PlayerControlAudioSetVolume :player="player" v-if="player" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PlayerControlAudioSetVolume from '@/components/PlayerControlAudioSetVolume'
export default {
  components: {
    PlayerControlAudioSetVolume,
  },
  props: {
    player: {
      type: Object,
      componentLoaded: false,
      required: true,
    },
  },
  watch: {
    player() {
      if (this.player && this.player.extended) {
        this.playerVolume = this.player.extended.volume
      }
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.cover-item {
  white-space: nowrap;
  img {
    border-radius: 5px;
  }
}
.audio-infos {
  flex: 1;
  min-width: 0; /* or some value */
  p {
    font-size: 1rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.artists {
  height: 26px;
  font-weight: 300;
  font-size: 0.9rem;
  color: rgba($h-main-text, 0.8);
}

.skip-btn {
  color: $h-main-text;
  transition: 0.2s;
  &:hover {
    color: $primary;
  }

  &:focus,
  &:active {
    outline: none;
    border: 0;
    box-shadow: none;
    color: $primary !important;
  }
}

$height: 30px;
$thumb-height: 15px;
$track-height: 3px;

// colors
$upper-color: #cfcfcf;
$lower-color: $primary;
$thumb-color: $primary;
$thumb-color-disabled: #868686;
$thumb-hover-color: #e92d0e;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100%
  50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100%
  50% / 100% $track-height no-repeat transparent;
$lower-background-disabled: linear-gradient(
    to bottom,
    $thumb-color-disabled,
    $thumb-color-disabled
  )
  100% 50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }
  @return $val;
}

.range {
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: $height;
  overflow: hidden;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

// Webkit
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
  transition: 0.2s;
}
.range.disabled::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background-disabled;
}

.range::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  top: 50%;
  margin-top: (-$thumb-height/2);
  box-shadow: webkit-slider-thumb-shadow();
  transition: background-color 150ms;
}
.range.disabled::-webkit-slider-thumb {
  transition: 0.2s;
  background: $thumb-color-disabled;
  transition: background-color 150ms;
}

// Firefox
.range::-moz-range-track,
.range::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

.range::-moz-range-progress {
  background: $lower-background;
  transition: 0.2s;
}
.range.disabled::-moz-range-progress {
  background: $lower-background-disabled;
}

.range::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}
.range.disabled::-moz-range-thumb {
  background: $thumb-color-disabled;
}

// Internet Exploder
.range::-ms-track {
  width: 100%;
  height: $height;
  border: 0;
  // color needed to hide track marks
  color: transparent;
  background: transparent;
}

.range::-ms-fill-lower {
  background: $lower-background;
}

.range::-ms-fill-upper {
  background: $upper-background;
}

.range::-ms-thumb {
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  // IE Edge thinks it can support -webkit prefixes
  top: 0;
  margin: 0;
  box-shadow: none;
}

.range:hover,
.range:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-color;
  }
  &::-moz-range-thumb {
    background-color: $thumb-color;
  }
  &::-ms-thumb {
    background-color: $thumb-color;
  }
}
.range.disabled:hover,
.range.disabled:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-color-disabled;
  }
  &::-moz-range-thumb {
    background-color: $thumb-color-disabled;
  }
  &::-ms-thumb {
    background-color: $thumb-color-disabled;
  }
}
.range.disabled:hover,
.range.disabled:focus {
  cursor: not-allowed;
}
</style>
