<template>
  <h-card class="control-audio-card">
    <audio
      id="audio"
      class="d-none"
      ref="audioPige"
      controls
      volume="0"
      @stalled="stalledAudio"
    >
      <source :src="pigeSrc" type="audio/mp3" />
    </audio>
    <div class="on-top">
      <h4 class="text-center mb-4">Player {{ player.name }}</h4>
      <b-row class="on-top">
        <b-col>
          <div class="d-flex align-items-center">
            <div class="d-flex" v-if="player.idPlayer">
              <PlayersItemStatus
                :key="refreshStatus"
                withLabel
                :heartbeat="player.heartbeat"
                :connected="player.connected"
                :player="player"
              />
            </div>
            <b-skeleton v-else width="320px" class="mt-1"></b-skeleton>
            <h-button
              v-if="player && player.name"
              @click="unpairPlayer"
              transp
              blue
              class="no-white-space ml-auto"
            >
              {{ $t('player.unpair') }}
            </h-button>
            <b-skeleton
              v-else
              width="150px"
              height="44px"
              class="skeleton-radius mt-1 ml-auto"
            ></b-skeleton>
          </div>
          <b-row>
            <b-col cols="12" class="mt-4 mb-4">
              <template
                v-if="!fetchingPlaylistsPlayable && player && player.extended"
              >
                <template v-if="fluxOptions && fluxOptions.length > 6">
                  <span class="d-block mb-1">{{
                    $t('player.currentPlaylist')
                  }}</span>
                  <h-select-flux
                    :disabled="disableFlux"
                    v-if="player"
                    v-model="flux"
                    :value="flux"
                    :placeholder="$t('player.choosePlaylist')"
                    :playerType="player.offer"
                    :options="fluxOptions"
                    @input="setNewFlux"
                  ></h-select-flux>
                </template>
                <template v-else>
                  <SelectFluxMosaic
                    v-if="fluxOptions && fluxOptions.length > 1"
                    :disabled="disableFlux"
                    :flux="fluxOptions"
                    :currentFlux="flux"
                    @changeFlux="setNewFlux"
                  ></SelectFluxMosaic>
                </template>
              </template>
              <div v-else class="d-flex">
                <b-skeleton
                  v-for="(one, i) in 6"
                  :key="i"
                  class="flux-image-skeleton mr-2"
                ></b-skeleton>
              </div>
            </b-col>
          </b-row>

          <template v-if="!fetchingPlayer && player && player.extended">
            <PlayersControlAudioCurrentPlaying
              v-if="
                player &&
                  player.extended.currentTrack &&
                  player.extended.currentTrack.title
              "
              :song="player.extended.currentTrack"
              :player="player"
            />
            <PlayersControlAudioCurrentPlayingEmpty v-else :player="player" />
          </template>
          <template v-else>
            <PlayersControlAudioCurrentPlayingSkeleton />
          </template>
        </b-col>
      </b-row>
      <b-row class="on-top mt-4 mb-1">
        <b-col>
          <template v-if="player && player.name">
            <router-link
              class="ml-auto"
              :to="{
                path: `/player${isSingleSite ? '' : 's'}/${
                  player.idPlayer
                }/playlist`,
                query: { player: player.name },
              }"
            >
              <h-button transp orange class="no-white-space">
                <b-icon icon="music-note-list" class="mr-2"></b-icon
                >{{ $t('player.seePlaylist') }}
              </h-button>
            </router-link>
            <!-- <h-button
              v-if="canListenPige"
              @click="listenFlux()"
              transp
              orange
              class="no-white-space ml-2"
            >
              <b-icon
                :icon="listening ? 'pause-fill' : 'play-fill'"
                class="mr-2"
              ></b-icon>
              <span v-if="listening">Arrêt de l'écoute</span>
              <span v-else>Ecoute de pige</span>
            </h-button> -->
          </template>
          <div class="d-flex" v-else>
            <b-skeleton
              width="170px"
              height="44px"
              class="skeleton-radius mt-1"
            ></b-skeleton>
            <!-- <b-skeleton
              width="170px"
              height="44px"
              class="skeleton-radius mt-1 ml-2"
            ></b-skeleton> -->
          </div>
        </b-col>
      </b-row>
    </div>
    <PlayersControlAudioBars
      v-if="player && player.idPlayer && canListenPige"
      :displayBars="displayBars"
    />
  </h-card>
</template>
<script>
import PlayersControlAudioCurrentPlaying from '@/components/PlayersControlAudioCurrentPlaying'
import PlayersControlAudioCurrentPlayingSkeleton from '@/components/skeleton/PlayersControlAudioCurrentPlayingSkeleton'
import PlayersControlAudioCurrentPlayingEmpty from '@/components/PlayersControlAudioCurrentPlayingEmpty'
import PlayersItemStatus from '@/components/PlayersItemStatus'
import PlayersControlAudioBars from '@/components/PlayersControlAudioBars'
import SelectFluxMosaic from '@/components/SelectFluxMosaic'
import { mapGetters, mapState } from 'vuex'
import playerService from '@/services/playerService'
export default {
  components: {
    PlayersControlAudioCurrentPlaying,
    PlayersControlAudioCurrentPlayingSkeleton,
    PlayersControlAudioCurrentPlayingEmpty,
    PlayersItemStatus,
    PlayersControlAudioBars,
    SelectFluxMosaic,
  },
  data() {
    return {
      disableFlux: false,
      flux: 0,
      listening: false,
      pigeSrc: null,
      displayBars: false,
    }
  },
  props: {
    player: {
      type: Object,
      componentLoaded: false,
      required: true,
    },
    refreshStatus: Number,
  },
  computed: {
    skeletonOptionsPlayable() {
      return this.skeletonOptions.filter((e) => e.skeletonPlayable)
    },
    ...mapState({
      selectedSite: (state) => state.user.selectedSite,
      fetchingPlayer: (state) => state.player.fetchingPlayer,
      fetchingPlaylistsPlayable: (state) =>
        state.musique.fetchingPlaylistsPlayable,
      streamOptions: (state) => state.musique.streams,
      canListenPige: (state) =>
        state.user?.user?.rights?.includes('MYHUBCAST_PIGE'),
    }),
    ...mapGetters({
      isSingleSite: 'user/isSingleSite',
      skeletonOptions: 'musique/skeletonOptions',
    }),
    fluxOptions() {
      const concat = this.streamOptions.concat(this.skeletonOptionsPlayable)
      return concat
    },
  },
  watch: {
    player: {
      handler(val) {
        if (val && val.extended) {
          this.$store.commit('user/SET_SELECTED_SITE', {
            id: parseInt(val.idPlayer),
            name: val.name,
          })
          this.fetchPlaylists()
          if (val.extended.currentPlaybackType >= 0) {
            this.flux =
              val.extended.currentPlaybackType === 0
                ? val.extended.currentStream
                : val.extended.currentSkeleton
          } else this.flux = -1
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchPlaylists() {
      this.$store.commit('musique/SET_FETCH_PLAYLIST_PLAYABLE_STATE', true)
      await this.$store.dispatch('musique/_getStreams')
      await this.$store.dispatch('musique/_getSkeletons')
      this.$store.commit('musique/SET_FETCH_PLAYLIST_PLAYABLE_STATE', false)

      if (this.streamOptions.length === 0 && this.skeletonOptions.length > 0)
        this.$store.dispatch('musique/_pushDefaultStream')
      if (this.player.extended) {
        if (this.player.extended.currentPlaybackType >= 0) {
          this.flux =
            this.player.extended.currentPlaybackType === 0
              ? this.player.extended.currentStream
              : this.player.extended.currentSkeleton
        } else this.flux = -1
      }
    },
    setNewFlux(value, noToast) {
      if (!this.disableFlux) {
        this.flux = value
        this.disableFlux = true
        setTimeout(() => {
          this.disableFlux = false
        }, 5000)
        const params = {
          IdSite: this.player.idPlayer,
          IdStream: value,
          playbackType: this.findPlayBackTypeSource(value),
          idSkeleton: value,
        }

        try {
          playerService.setChangeFlux(params)
          if (!noToast) {
            this.$toast.success(this.$t('player.playlistChangeSuccess'))
          }
        } catch (e) {
          this.$toast.error(this.$t('errors.generic'))
        }
      }
    },
    findPlayBackTypeSource(id) {
      if (id === -1) return -1

      const isStream = this.streamOptions.find((e) => e.idStream === id)
      const isSkeleton = this.skeletonOptionsPlayable.find(
        (e) => e.idSkeleton === id
      )
      if (isStream) return 0
      else if (isSkeleton) return 1
    },
    // listenFlux() {
    //   if (this.listening) {
    //     //pause
    //     this.$refs.audioPige.pause()
    //     this.pigeSrc = null
    //     this.$refs.audioPige.load()
    //     this.displayBars = false
    //     this.disableFlux = false
    //   } else {
    //     //play
    //     //this.pigeSrc = ''
    //     this.$refs.audioPige.load()
    //     this.$refs.audioPige.play()
    //     if (this.isPlaying(this.$refs.audioPige)) this.displayBars = true
    //     this.disableFlux = true
    //   }
    //   this.listening = !this.listening
    // },
    stalledAudio() {
      this.listening = false
      this.displayBars = false
      this.disableFlux = false
    },
    isPlaying(audelem) {
      return !audelem.paused
    },
    unpairPlayer() {
      this.$emit('unpair')
    },
  },
}
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
.flux-image-skeleton {
  width: 70px;
  height: 70px;
}
</style>
